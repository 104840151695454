<template>
    <div class="Input-select">
        <select :value="selectedOption" class="form-control" :class="customClass" aria-label="Select" @input="onChange">
            <option value="" disabled selected>{{placeholder}}</option>
            <option v-for="(option, index) in options" v-bind:value="option.value" :key="index">
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: function() {
          return [];
      }
    },
    selectedOption: {
      type: String,
      default: '',
    },
    placeholder: {
        type: String,
        default: 'Choose Option'
    },
    customClass: {
        type: String,
        default: 'form-select-restaurant'
    }
  },
  data() {// only data for test!! this if for remove later!
    return {
        selected: this.selectedOption != ''? this.selectedOption : '',
        optionsLocal: [
            {
                text: 'option 1',
                value: '1'
            },
             {
                text: 'option 2',
                value: '2'
            }, {
                text: 'option 3',
                value: '3'
            }, {
                text: 'option 4',
                value: '4'
            }
        ]
    };
  },
  methods: {
    onChange: function(event) {
        this.$emit("update:selectedOption", event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";

.form-select-restaurant {
    background-color: transparent !important;
    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $second-grey-color;
    }
    border-radius: 0;
    border-bottom: 1px solid $second-grey-color;
}

.suggest-resturant__select--style {
    font-size: 20px;
    color: $primary-grey-color;
    padding: 10px 30px;
    border-radius: 8px;
}
</style>
