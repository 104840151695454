<template>
    <div class="likes-counter align-left">
        <div class="likes-counter-container">
            <div class="month-reserv-icon"><IconDog/></div>
            <div class="month-reserv-counter" :class="[minified ? 'month-reserv-counter--minified':'']">
                <h4> {{ counter }} </h4>
                <span v-if="!minified"> {{$filters.pluralize(counter,'Pessoa aprova','Pessoas aprovam') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import IconDog from "./icons/FidDog.vue"

export default {
    name: "Likes Counter",
    components: {
        IconDog
    },
    props: {
        counter: {
            type: Number,
            default: 0
        },
        minified: {
            type: Boolean,
            default: false
        }
    },
    data() { // This is only data for design! and test || TODO -- getting from service
        return {
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../styles/_variables";

.likes-counter {
    .likes-counter-container {
        text-align: left;
        display: flex;
        .month-reserv-counter{
            h4 {
                line-height: 26px;
                margin: 0;
                font-size: 22px;
                color: $primary-grey-color;
                font-family: 'AzoSans-Bold';
                @media (max-width: 1199.98px) {
                    margin: 0;
                    line-height: 21px;
                    font-size: 17px;
                }
            }
            span {
                font-family: 'AzoSans-Light';
                line-height: 17px;
                margin: 0;
                font-size: 15px;
                color: $primary-grey-color;
                @media (max-width: 1199.98px) {
                    line-height: 12px;
                    margin: 0;
                    font-size: 12px;
                }
            }

            &.month-reserv-counter--minified {
                align-self: center;
            }
        }
    }
    .month-reserv-icon {
        display: flex;
        width: 45px;
        height: 45px;
        background: $primary-red;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        margin-right: 15px;

        @media (max-width: 1199.98px) {
            width: 35px;
            height: 35px;
            margin-right: 10px;
        }

        > svg {
            width: 18px;
            height: auto;
        }
    }
}
</style>