<template>
  <div class="select-collapsible">
        <a ref="selectheader" class="select-collapsible-header collapsed" data-bs-toggle="collapse" href="#collapse-select" role="button" aria-expanded="true" aria-controls="collapse-select">
                <span class="select-collapsible-header-title">{{ selected }}</span>
                <span class="select-collapsible_arrow"><Arrow/></span>
        </a>
        <div class="select-collapsible-header-content collapse" id="collapse-select">
            <template v-for="(item, index) in options" :key="index">
                <div v-if="selected != item" class="select-collapsible-header-option" @click="selectItem(item)">
                    {{ item }}
                </div>
            </template>
        </div>
  </div>
</template>

<script>
import Arrow from "../components/icons/Arrow.vue"
export default {
    name: "Select Collapsible",
    props: {
        selected: {
            type: String,
             default: ''
        },
        options: {
            type: Array,
            default: function(){
                return [];
            }
        }
    },
     data() {
        return {
            localOptions: this.options,
            localSelected: this.selected,
        }
    },
    components: {
        Arrow,
    },
    methods: {
        selectItem(item) {
            this.localSelected = item;
            this.$refs.selectheader.click();
            this.$emit('select', this.localSelected);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
.select-collapsible-header {
    text-decoration: none;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $second-grey-color;
}
.select-collapsible-header-title {
    font-family: 'AzoSans-Bold';
    font-size: 19px;
    line-height: 19px;
    color: $primary-grey-color;
    text-transform: uppercase;
    border-bottom: 7px solid $primary-red;
    padding: 20px 15px 18px 15px;
    margin-bottom: -3.5px;
    margin-left:25px;
    @media (max-width: 425px) {
        margin-left:15px;
    }
}

.select-collapsible-header-option:nth-child(even) {
    background-color: $f2-grey-color;
}

.select-collapsible-header-option:nth-child(odd) {
    background-color: white;
}

.select-collapsible-header-option {
    font-family: 'AzoSans-Light';
    font-size: 19px;
    line-height: 19px;
    color: $primary-grey-color;
    text-transform: uppercase;
    padding: 20px 0 20px 35px;
    border-bottom: 1px solid $second-grey-color;
    cursor: pointer;
}

.select-collapsible_arrow {
    padding-right: 15px;
}


.select-collapsible {
    padding-bottom: 3px;
}
</style>