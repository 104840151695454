import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
/*import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./styles/_base.scss"*/


// Make BootstrapVue available throughout your project
const app = createApp(App).use(router);



app.config.globalProperties.$filters = {
    trim(text,length,suffix) {
        if (text.length > length) {
            return text.substring(0, length) + suffix;
        } else {
            return text;
        }
    },
    withBrTags(text){
        return text.replace(/\n/g, '<br/>');
    },
    pluralize(counter,sing,plu){
        if (counter == 1) {
            return sing
        } else {
            return plu;
        }
    }
    
  }

  app.mount('#app');