<template>
  <div class="recommendations-search">
    <div class="border-top recommendations-search-header">
      <div class="container">
        <div class="row">
          <div
            class="
              recommendations-search-header__title
              col-md-7
              text-lg-end
              border-right
              text-center
              py-5
            "
          >
            <div class="text-lg-start text-center d-inline-block pe-lg-5">
              <span class="light-font">Precisa<br />de</span>
              <span class="bold-font"> reservar<br />uma mesa?</span>
            </div>
          </div>
          <div class="recommendations-search-header__info col-md-5 py-lg-5 py-3">
            <div class="ps-5">
              <div class="recommendations-search-header_info-title bold-font">
                Siga estas <br />
                recomendações.
              </div>
              <div class="recommendations-search-header_info-content">
                <p>
                  Vai optar por carne, peixe ou vegetariano?<br />
                  Tem preferência pela região?<br />
                  Preencha os filtros abaixo e nós<br />
                  recomendamos. Depois, é só fazer<br />
                  a reserva e sentar-se à mesa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="recommendations-search__container lightgrey-back">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="recommendations-search__form ps-md-5">
              <div class="row align-items-center gx-md-5">
                <div class="recommendations-search__form-select col-md-3">
                  <InputSelect
                    placeholder="Região"
                    v-model:selectedOption="selectedRegion"
                    :options="optionsLocal"
                  ></InputSelect>
                </div>
                <div class="recommendations-search__form-select col-md-3">
                  <InputSelect
                    placeholder="Especialidade"
                    v-model:selectedOption="selectedSpecialty"
                    :options="optionsLocalSpec"
                  ></InputSelect>
                </div>
                <div class="recommendations-search__form-select col-md-4">
                  <div class="row">
                    <div class="recommendations-search__form-from-to col-8">
                      <div class="recommendations-search__form-from-to--select">
                        <InputSelect
                          placeholder="Valor médio"
                          v-model:selectedOption="selectedFrom"
                          :options="optionsLocalPrice"
                        ></InputSelect>
                      </div>
                    </div>
                    <div
                      class="recommendations-search__form-from-to-info col-4"
                    >
                      por pessoa
                    </div>
                  </div>
                </div>
                <div class="recommendations-search__form-btn col-md-2">
                  <button
                    class="recommendations-search__form-btn-submit"
                    @click="getResults"
                  >
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="recommnedations-search_results-total"
              v-if="searchResults.length > 1 || searched"
            >
              Encontramos <span>{{ counterResults }}</span>
            </div>
            <div
              class="recommnedations-search_results"
              v-if="searchResults.length > 0"
            >
              <div
                class="recommnedations-search_results-item"
                v-for="(item, index) in searchResults"
                :key="index"
              >
                <RecommendationsItem :special="special" :data="item"></RecommendationsItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "./InputSelect.vue";
import RecommendationsItem from "./RecommendationsItem.vue";
import SearchIcon from "./icons/SearchIcon.vue";
import axios from "axios";
export default {
  name: "Recommendations search",
  components: {
    InputSelect,
    SearchIcon,
    RecommendationsItem,
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      special: true,
      searched: false,
      selectedRegion: "",
      selectedSpecialty: "",
      selectedFrom: "",
      selectedTo: "",
      optionsLocal: [
        {
          text: "Norte",
          value: "Norte",
        },
        {
          text: "Centro",
          value: "Centro",
        },
        {
          text: "Sul",
          value: "Sul",
        },
        {
          text: "Açores",
          value: "Açores",
        },
        {
          text: "Madeira",
          value: "Madeira",
        },
        {
          text: "Todas",
          value: "Todas",
        },
      ],
      optionsLocalSpec: [
        {
          text: "Portuguesa",
          value: "Portuguesa",
        },
        {
          text: "Internacional",
          value: "Internacional",
        },
        {
          text: "Vegan/Vegetariana",
          value: "Vegan-Vegetariana",
        },
        {
          text: "Outros",
          value: "Outros",
        },
        {
          text: "Todas",
          value: "Todas",
        },
      ],
      optionsLocalPrice: [
        {
          text: "Até 25€",
          value: "25",
        },
        {
          text: "25€ a 50€",
          value: "50",
        },
        {
          text: "50€ a 75€",
          value: "75",
        },
        {
          text: "75€ a 100€",
          value: "100",
        },
        {
          text: "Superior a 100€",
          value: "100more",
        },
        {
          text: "Todos",
          value: "Todos",
        },
      ],
      searchResults: [],
    };
  },
  methods: {
    getResults() {
      this.searched = true;
      const params = {
        region: this.selectedRegion,
        spec: this.selectedSpecialty,
        price: this.selectedFrom,
      };
      axios.get(route("search"), { params }).then((response) => {
        this.searchResults = response.data;
      });
    },
  },
  computed: {
    counterResults() {
      var stringResult =
        this.searchResults.length +
        " " +
        (this.searchResults.length == 1 ? "recomendação" : "recomendações") +
        "";
      return stringResult;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
.recommendations-search__form {
  background-color: white;
  border-radius: 40px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 12px 20px 5px $second-grey-color;

  @media (max-width: 1199.98px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 80px;
    position: relative;

    .recommendations-search__form-select {
      margin-bottom: 30px;
    }
  }
}
.recommendations-search {
  .recommendations-search-header {
    background: url(../images/whiteseparator.png) no-repeat bottom;
    background-size: cover;
    position: relative;
    z-index: 1;
  }
  .recommendations-search-header__info,
  .recommendations-search-header__title {
    padding-bottom: 130px !important;
  }
    .recommendations-search-header__title {
        @media (max-width: 1199.98px) {
             padding-bottom: 0 !important;
        }
    }
  .recommendations-search-header__title span {
    font-size: 45px;
    line-height: 65px;
    text-transform: uppercase;
    max-width: 680px;
    margin: 0 auto;

    @media (max-width: 1199.98px) {
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 35px;
    }
  }

  .recommendations-search-header_info-title {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 25px;

    @media (max-width: 1199.98px) {
      text-align: center;
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }

  .recommendations-search-header_info-content {
    font-size: 20px;
    max-width: 365px;

    @media (max-width: 1199.98px) {
      text-align: center;
      font-size: 18px;
    }
  }

  .recommendations-search-header__info {
    align-self: center;

    @media (max-width: 1199.98px) {
        > div {
            padding-left: 0 !important;
        }
    }
  }

  .recommendations-search__form-from-to {
    display: flex;
    justify-content: space-evenly;
    .recommendations-search__form-from-to--select {
      flex: 1;
    }

    .recommendations-search__form-from-to--separator {
      padding: 0 10px;
      align-self: center;
    }
  }

  .recommendations-search__form-from-to-info {
    align-self: center;
  }

  .recommendations-search__form-btn-submit {
    background-color: $primary-red;
    border-radius: 40px;
    padding: 45px 0px;
    width: 100%;
    border: none;
    text-decoration: none;

    margin-right: -1px;
    float: right;
    > svg {
      width: 45px;
      height: auto;
    }

    @media (max-width: 1199.98px) {
      padding: 18px 0px;
      border-radius: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .recommendations-search__container {
    padding-top: 82.5px;
    margin-top: -165px;
    padding-bottom: 60px;
    @media (max-width: 768px) {
    padding-bottom: 20px;
      
    }
  }

  .recommnedations-search_results {
    max-height: 750px;
    overflow: hidden;
    overflow-y: scroll;

    // todo add scroll style!

    .recommnedations-search_results-item {
      padding: 15px 0px;
    }
  }

  .recommnedations-search_results-total {
    border-bottom: 1px solid $primary-grey-color;
    &,
    span{

        font-size: 36px;
    line-height: 36px;
    margin-top: 100px;
    padding-bottom: 10px;
    }

    span {
      font-family: "AzoSans-Bold";
      color: $primary-red;
    }

    @media (max-width: 1199.98px) {
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
}
</style>