<template>
  <div class="image-full">
    <img class="img-fluid" :class="aftertop" :src="imageUrl" />
    <div
      class="image-full-overlay"
      :class="classExtra"
      :v-if="classExtra != ''"
    ></div>
  </div>
</template>
<script>
export default {
  name: "Image full",
  props: {
    classExtra: {
      type: String,
      default: "",
    },
    aftertop: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.image-full {
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    max-height: 800px;
    &.aftertop {
      max-height: initial;
      height: auto;
      margin-top: -110px;
        @media (min-width: 769px) {
        margin-bottom: -140px;
    }

        @media (max-width: 680px) {
   
      margin-top: -70px;
    }
    }
  }
}
</style>