<template>
  <div class="home">
    <div class="container special-top-cont py-2 d-none d-md-block">
    </div>
    <div class="mt-4 mt-md-0">
      <MonthReservations />
    </div>
    <div class="container pb-0 mb-3 z-index-3 position-relative ">
      <div class="col-lg-8 offset-lg-2 pb-4">
          <router-link :to="{ name: 'Reservations'}" class="w-100 btn btn-dark btn-bold">Ver meses anteriores</router-link>
      </div>
  </div>
    <div class="lightgrey-back position-relative z-index-2">
      <SliderRestaurants />
    </div>
    <div class="transp-back z-index-3 position-relative">
      <ImageFull :aftertop="afterTop" :imageUrl="imageUrl" />
    </div>
    <RecommendationsSearch />
    <SuggestRestaurant />
    <div class="">
      <ImageFull class-extra="parsley-back" :imageUrl="imageUrlBottom" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MonthReservations from "../components/MonthReservations.vue";
import Quote from "../components/Quote.vue";
import SliderRestaurants from "../components/SliderRestaurants.vue";
import ImageFull from "../components/ImageFull.vue";
import RecommendationsSearch from "../components/RecommendationsSearch.vue";
import SuggestRestaurant from "../components/SuggestRestaurant.vue";

export default {
  name: "Home",
  components: {
    MonthReservations,
    Quote,
    SliderRestaurants,
    ImageFull,
    RecommendationsSearch,
    SuggestRestaurant,
  },
  /*created() {
    console.log(this.authUser.id);
  },*/
  data() {
    return {
      imageUrl: require("../images/middle@2x.png").default,
      imageUrlBottom: require("../images/image-bottom.jpg").default,
      authUser: window.authUser,
      afterTop: 'aftertop',
    };
  },
};
</script>

<style lang="scss" scoped>

.special-top-cont{

    border-right: 1px solid #707070;
}
</style>