<template>
<svg id="noun_hours_2303152" xmlns="http://www.w3.org/2000/svg" width="16.026" height="16.026" viewBox="0 0 16.026 16.026">
<g id="Group_55" data-name="Group 55" transform="translate(0 0)">
<g id="Group_54" data-name="Group 54">
    <g id="Group_53" data-name="Group 53">
    <g id="Group_52" data-name="Group 52">
        <g id="Group_51" data-name="Group 51">
        <g id="Group_50" data-name="Group 50">
            <path id="Path_128" data-name="Path 128" d="M8.013,14.674a6.66,6.66,0,1,1,6.659-6.659,6.659,6.659,0,0,1-6.659,6.659M8.013,0A8.013,8.013,0,1,1,0,8.014,8.014,8.014,0,0,1,8.013,0Zm0,2.708a.677.677,0,0,0-.677.678v4.62a.677.677,0,0,0,.2.486l3.272,3.271a.676.676,0,0,0,.957,0,.677.677,0,0,0,0-.957L8.69,7.735V3.387A.677.677,0,0,0,8.013,2.708Z" fill="#fff" fill-rule="evenodd"/>
        </g>
        </g>
    </g>
    </g>
</g>
</g>
</svg>
</template>