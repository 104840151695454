<template>
  <div class="reservations">
    <div class="">
      <div class="">
        <div class="container">
          <div class="reservations__header row">
            <div class="reservations__header-back col-md-3 ps-lg-5">
              <router-link class="btn-fid-grey btn-back" :to="{ name: 'Home' }">
                Voltar
              </router-link>
            </div>
            <div class="reservations__header-title col-md-8 text-md-end text-start mt-4 mt-md-0">
              Reservas do mês <span>{{ localYear }}</span>
            </div>
          </div>
        </div>
        <div class="">
          <div class="reservations__table d-none d-lg-block">
            <div class="header-table border-bottom border-top">
              <div class="container">
                <div class="row">
                  <div class="title col-1 border-right">
                    <span></span>
                  </div>
                  <div class="elements col-11">
                    <div
                      class="element border-right"
                      :style="defineStyle()"
                      v-for="(item, index) in localtestRegions"
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="body-table border-bottom">
              <div v-for="(item, index) in localtestData" :key="index">
                <div class="container">
                  <div class="row">
                    <div class="title col-1 border-right">
                      <span> {{ item.name }}</span>
                    </div>
                    <div class="elements col-11">
                      <div
                        class="element border-right"
                        :style="defineStyle()"
                        v-for="(reg, key2) in localtestRegions"
                        :key="key2"
                      >
                        <div
                          v-for="(restaurant, key) in item.restaurants"
                          :key="key"
                        >
                          <ReservationsItem
                            :data="restaurant"
                            v-if="restaurant.region == reg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reservations_list-container d-block d-lg-none">
      <div class="reservations_list-select">
        <SelectCollapsible
          :options="localtestRegions"
          :selected="localSelectedRegion"
          @select="selectRegion"
        ></SelectCollapsible>
      </div>
      <div class="reservations_list-content border-bottom mb-5">
        <div
          class="reservations_list-select-item"
          v-for="(restaurant, key) in listReservations"
          :key="key"
        >
          <ReservationsItem :data="restaurant" />
        </div>
      </div>
    </div>
    <div class="container">
    <div class="reservations__btn-back-bottom">
      <router-link
        :to="{ name: 'Home' }"
        class="w-100 mb-100 btn btn-dark btn-bold"
        >Voltar à página principal</router-link
      >
    </div>
    </div>
    <ImageFull class-extra="parsley-back" :imageUrl="imageUrlBottom" />
  </div>
</template>

<script>
import ReservationsItem from "../components/ReservationsItem.vue";
import ImageFull from "../components/ImageFull.vue";
import SelectCollapsible from "../components/SelectCollapsible.vue";
import axios from "axios";
export default {
  name: "Reservations",
  props: {},
  components: {
    ReservationsItem,
    ImageFull,
    SelectCollapsible,
  },
  mounted() {
    //  call fetch Data function to get values!
    this.fetchData();
  },
  data() {
    // This is only data for design! and test || TODO -- getting from service
    return {
      imageUrlBottom: "",
      localSelectedRegion: "",
      localYear: "",
      localtestRegions: [],
      localtestData: [],
    };
  },
  methods: {
    fetchData() {
      axios.get(route("top-year")).then((response) => {
        var data = response.data;
        this.imageUrlBottom =
          require("../images/reservations_bottom.jpg").default;
        this.localSelectedRegion = data.regions[0];
        this.localYear = data.localYear;
        this.localtestRegions = data.regions;
        this.localtestData = data.restaurants;
      });
    },
    getDataByRegions(region) {
      return this.localListRest.filter((restaurant) => {
        return restaurant.region == region;
      });
    },
    defineStyle() {
      var wid = 100 / this.localtestRegions.length;
      return "width: " + wid + "%;";
    },
    defineBorders(index, dataArray) {
      if (index < dataArray.length - 1) {
        return "reser-border-start";
      }

      return "reser-border-start reser-border-end";
    },
    selectRegion(value) {
      this.localSelectedRegion = value;
    },
  },
  computed: {
    listReservations() {
      var results = [];
      for (var i = 0; i < this.localtestData.length; i++) {
        var rest = this.localtestData[i].restaurants;

        for (var y = 0; y < rest.length; y++) {
          if (this.localSelectedRegion == rest[y].region) {
            results.push(rest[y]);
          }
        }
      }

      return results;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
.elements {
  .element {
    height: 100%;
    min-height: 10px;
    float: left;
    padding: 10px;
    padding: 30px 20px;
  }
}
.btn-back {
  border: none;
  text-decoration: none;
  padding: 17px 0px;
  font-family: "AzoSans-Black";
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 8px;
  display: block;
  text-align: center;
  width: 100%;
  max-width: 170px;
  justify-content: center;
}

.reservations__header-title {
  &,
  span {
    font-family: "AzoSans-Light";
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
    text-align: right;
  }
  color: $second-grey-color;

  span {
    color: $primary-red;
    font-family: "AzoSans-Bold";
  }

  @media (max-width: 425px) {
      &,
      span{
    font-size: 30px;
    line-height: 36px;
    }
    &{
        border-top: 1px solid $primary-grey-color;
        padding-top: 30px;
    }
  }
}

.reservations__header {
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: center;
}
.header-table {
  background-color: $f2-grey-color;
  .element {
    font-family: "AzoSans-Light";
    font-size: 40px;
    line-height: 44px;
    color: $second-grey-color;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
  }
}
.body-table {
  > div {
    &:nth-child(even) {
      background-color: $f2-grey-color;
    }
  }

  .title {
    position: relative;
    span {
      font-family: "AzoSans-Light";
      font-size: 40px;
      line-height: 44px;
      color: $primary-red;
      text-transform: uppercase;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    }
  }
}
.reservations__table {
  margin-bottom: 100px;

  table {
    thead {
      height: 165px;
      background-color: $f2-grey-color;
      border-bottom: 1px solid $primary-grey-color;
    }

    tbody {
      border-top: unset;

      tr {
        &:nth-child(even) {
          background-color: $f2-grey-color;
        }
        th {
          position: relative;
          width: 165px;
          border-right: 1px solid $primary-grey-color;
          &,
          span {
            font-family: "AzoSans-Light";
            font-size: 50px;
            line-height: 54px;
            color: $primary-red;
            text-transform: uppercase;
          }

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          }
        }
        td {
          padding: 30px;
        }
      }
    }

    .reservations__table-regions {
      font-family: "AzoSans-Light";
      font-size: 50px;
      line-height: 54px;
      color: $second-grey-color;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
    }
  }
}
.reservations_list-select {
  background-color: $f2-grey-color;
}
.reservations_list-select-item {
  padding: 40px 25px;
  @media (max-width: 1199.98px) {

    padding-bottom:20px;
  }
  @media (max-width: 425px) {
    padding: 40px 15px;
  }
  &:nth-child(even) {
    background-color: $f2-grey-color;
  }
}

.reservations_list-container {
  border-top: 1px solid $second-grey-color;
}
.reser-border-start {
  border-left: 1px solid $primary-grey-color;
}

.reser-border-end {
  border-right: 1px solid $primary-grey-color;
}

.reservations__btn-back-bottom {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
</style>