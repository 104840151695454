// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/header-small@2x.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-logo[data-v-1e3d5589]{padding:52px 0;text-align:center}.header-container-min[data-v-1e3d5589]{background:#2b2b2b url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:cover;display:flex;flex-direction:column}.header-container-min .header-content[data-v-1e3d5589]{display:flex;flex:1;flex-direction:column;justify-content:space-evenly}", "",{"version":3,"sources":["webpack://./resources/components/HeaderMin.vue"],"names":[],"mappings":"AACA,8BAEI,cAAA,CADA,iBACJ,CAEA,uCACI,wEAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAJ,CAEI,uDACI,YAAA,CAEA,MAAA,CADA,qBAAA,CAEA,4BAAR","sourcesContent":["\r\n.header-logo {\r\n    text-align: center;\r\n    padding: 52px 0px;\r\n}\r\n.header-container-min {\r\n    background: #2B2B2B url(../images/header-small@2x.png) no-repeat top;\r\n    background-size: cover;\r\n  //  box-shadow: inset 0 0 150px #000000;\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    .header-content {\r\n        display: flex;\r\n        flex-direction: column;\r\n        flex: 1;\r\n        justify-content: space-evenly;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
