<template>
  <div class="month-reserv">
    <div class="month-reserv-header">
      <div class="container">
        <div class="row">
          <div class="month-reserv-title col-lg-6 offset-lg-2 text-start">
            Destaques
          </div>
          <div class="month-reserv-month col-lg-4 text-center">
            {{ month }}
          </div>
          <div class="month-reserv-flag-dog">
            <span><IconDog /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="month-reserv-content container border-right px-0 pb-3">
      <div class="month-reserv-tabs row d-none d-lg-block">
        <ul class="nav nav-fill col-lg-9 offset-lg-2">
          <li
            class="nav-item"
            :value="option"
            v-for="(option, index) in regions"
            :key="index"
            @click="onChangeRegion(option)"
          >
            <span
              class="nav-link"
              :class="{ active: option == selectedRegion }"
              aria-current="page"
              >{{ option }}</span
            >
          </li>
        </ul>
      </div>
      <div class="container">
        <div class="month-reserv-select d-block d-lg-none">
          <SelectCollapsible
            :options="regions"
            :selected="selectedRegion"
            @select="onChangeRegion"
          ></SelectCollapsible>
        </div>
        <div
          class="month-reserv-restaurant col-lg-9 offset-lg-2"
          v-if="Object.keys(restaurant).length"
        >
          <div class="month-reserv-restaurant-header row">
            <div class="col-lg-8">
              <div class="row align-items-center">
                <div
                  class="month-reserv-restaurant-address col-lg-6 align-left"
                >
                  <strong class="text-uppercase">{{ restaurant.name }}</strong
                  >, {{ restaurant.addresssec }}
                </div>
                <div
                  class="
                    month-reserv-restaurant-likes month-in-home
                    desktop
                    col-lg-6
                    align-left
                  "
                >
                  <LikesCounter :counter="restaurant.likes"></LikesCounter>
                </div>
              </div>
            </div>
          </div>
          <div class="month-reserv-restaurant-content row align-items-center">
            <div
              class="month-reserv-restaurant-image col-lg-8 position-relative"
            >
              <img
                :src="restaurant.images[0]"
                v-if="restaurant.images"
                class="img-fluid"
              />
              <div class="restaurant-item-flag-dog">
                <div class="restaurant-item-flag-title">
                  <div class="text-uppercase restaurant-item-flag-title-month">
                    {{ restaurant.month }}
                  </div>
                  <div class="restaurant-item-flag-title-year">
                    {{ restaurant.year }}
                  </div>
                </div>
                <span>
                  <IconDog />
                </span>
              </div>
            </div>
            <div
              class="
                month-reserv-restaurant-likes month-in-home
                mobile
                col-lg-6
                align-left
                mt-4 mt-4
              "
            >
              <LikesCounter :counter="restaurant.likes"></LikesCounter>
            </div>
            <div
              class="
                month-reserv-restaurant-description
                align-left
                col-lg-4
                mt-3
              "
            >
              <p
                v-if="restaurant.text"
                v-html="
                  $filters.withBrTags(
                    $filters.trim(restaurant.text, 377, '...')
                  )
                "
              ></p>
              <div class="view-more">
                <router-link
                  class="btn-fid-red btn-read-more"
                  :to="{
                    name: 'Restaurant',
                    params: { name: restaurant.name, id: restaurant.slug },
                  }"
                >
                  Ler crítica completa
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-5">
    <div class="quote border-top border-bottom">
      <div class="container">
        <div class="quote-container row col-lg-10 offset-lg-2">
          <div class="quote-user align-self-center col-lg-4">
            <div class="quote-user-info row">
              <div class="quote-user-image col-4">
                <img
                  v-if="restaurant.user"
                  class="img-fluid"
                  :src="restaurant.user.photo"
                />
              </div>
              <div
                v-if="restaurant.user"
                class="quote-user-data align-self-center col-8"
              >
                <div class="column align-left">
                  <div class="quote-user-bywho">Sugestão mediador</div>
                  <div class="quote-user-name">{{ restaurant.user.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="quote-text pt-5 align-self-center col-lg-8">
            {{ restaurant.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDog from "./icons/FidDog.vue";
import LikesCounter from "./LikesCounter.vue";
import SelectCollapsible from "../components/SelectCollapsible.vue";
import axios from "axios";
export default {
  name: "MonthReservations",
  components: {
    IconDog,
    LikesCounter,
    SelectCollapsible,
  },
  props: {},
  data() {
    return {
      month: "",
      selectedRegion: "",
      regions: [],
      restaurant: {},
      listRestaurants: [],
    };
  },
  computed: {
    accountInUSD() {
      return "$" + this.accountBalance;
    },
  },

  mounted() {
    //  call fetch Data function to get values!
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(route("top-current")).then((response) => {
        const json_data = response.data;
        this.month = json_data.currentMonth;
        this.selectedRegion =
          json_data.selectedRegion != undefined
            ? json_data.selectedRegion
            : json_data.regions[0];
        this.regions = json_data.regions;
        
        if (this.selectedRegion == "Agências") {
          this.restaurant = json_data.agency.find((obj) => {
            return obj.restaurant.agency == 1;
          });
        } else {
          this.restaurant = json_data.restaurants.find((obj) => {
            return (
              obj.restaurant && 
              obj.restaurant.agency != 1 &&
              obj.restaurant.region == this.selectedRegion
            );
          });
        }
        var text = this.restaurant.text;
        this.restaurant = this.restaurant.restaurant;
        this.restaurant.text = text;
        this.agency = json_data.agency;
        this.listRestaurants = json_data.restaurants;
      });

      // Example! -- TODO
      // fetch('todo/api/restaurantMonth')
      // .then(response => {
      //      var contentType = response.headers.get("content-type");
      //      console.log(contentType);
      //      if (!response.ok) {
      //         throw new Error("HTTP error " + response.status);
      //     }
      //     console.log(response);
      //     return response.json();
      // })
      // .then(json => {
      //     console.log(json);
      // })
      // .catch(error => {
      //     throw new Error("HTTP error " + error);
      // });
    },

    // Function on change region!
    onChangeRegion(value) {
      this.selectedRegion = value;
    

      if (this.selectedRegion == "Agências") {
          this.restaurant = this.agency.find((obj) => {
            return obj.restaurant.agency == 1;
          });
        } else {
          this.restaurant = this.listRestaurants.find((obj) => {
            return (
              obj.restaurant && 
              obj.restaurant.agency != 1 &&
              obj.restaurant.region == this.selectedRegion
            );
          });
        }
      var text = this.restaurant.text;
      this.restaurant = this.restaurant.restaurant;
      this.restaurant.text = text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";

.quote {
  .padding-vertical {
    padding: 50px 0px;
    @media (max-width: 768px) {
      padding: 42px 0px;
    }
  }

  .quote-user-image {
    img {
      object-fit: cover;
      width: 112px;
      max-width: 100%;
      height: auto;
      border-radius: 112px;
    }
  }

  .quote-user-bywho {
    font-family: "AzoSans-Regular";
    font-size: 18px;
    line-height: 18px;
    color: $primary-grey-color;
    margin-bottom: 10px;
  }

  .quote-user-name {
    font-family: "AzoSans-Bold";
    font-size: 20px;
    line-height: 24px;
    color: $primary-grey-color;

    @media (max-width: 768px) {
      font-size: 21px;
      line-height: 21px;
    }
  }

  .quote-text {
    quotes: "“" "”";
    font-size: 20px;
    line-height: 25px;
    position: relative;

    &.quote-margin-top {
      margin-top: 50px;
    }

    @media (max-width: 768px) {
      margin-top: 45px;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .quote-text::before {
    position: absolute;
    font-family: sans-serif;
    content: open-quote;
    font-size: 110px;
    color: $primary-red;
    top: 33px;
  }

  .quote-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.month-reserv {
  .month-reserv-header {
    border-top: 1px solid $second-grey-color;
    border-bottom: 1px solid $second-grey-color;
    position: relative;
    > div {
      border-right: 1px solid #707070;
      @media (max-width: 768px) {
        border-right: unset;
      }
    }
    .row > div {
      padding: 5px 0;

      @media (max-width: 768px) {
        padding: 25px 0;
      }

      &:first-child {
        border-right: 1px solid $second-grey-color;
        margin-right: -1px;

        @media (max-width: 768px) {
          border-right: unset;
          border-bottom: 1px solid $second-grey-color;
          margin-right: 0px;
          white-space: pre-wrap;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    .month-reserv-title {
      font-size: 40px;
      line-height: 53px;
      color: $primary-grey-color;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
        padding-left: 30% !important;
      }
    }
    .month-reserv-month {
      font-size: 40px;
      line-height: 53px;
      font-family: "AzoSans-Light";
      color: $primary-red;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
        padding-left: 30% !important;
        text-align: left !important;
      }
    }

    .month-reserv-flag-dog {
      position: absolute;
      background: $primary-red;
      width: 95px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 0 0 45px 0;

      @media (max-width: 768px) {
        width: 72px;
        height: 180px;
        left: 5%;

        svg {
          width: 40px;
          height: auto;
        }
      }
    }
  }
}
.month-reserv-restaurant-content {
  .month-reserv-restaurant-image {
    @media (min-width: 769px) {
      height: 470px;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%; // maybe remove this when images styles are ready
    }
  }

  /* sm */

  @media (max-width: 768px) {
    .month-reserv-restaurant-description {
      text-align: center;
      margin-top: 20px;
    }
  }
}

.month-reserv-select {
  margin-top: 45px;
  margin-bottom: 25px;
}

.month-reserv-content {
  @media (max-width: 768px) {
    border-right: unset !important;
  }
  .month-reserv-tabs {
    padding: 5px 0 25px 0px;
    @media (max-width: 768px) {
      margin: 30px 0px;
      .nav {
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
      }
    }
    .nav {
      border-bottom: 1px solid $second-grey-color;
      padding-right: 0px;
    }
    span {
      cursor: pointer;
      font-size: 27px;
      line-height: 30px;
      text-transform: uppercase;
      font-family: "AzoSans-Light";
      color: $primary-grey-color;

      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 26px;
      }

      &.active {
        border-bottom: 11px solid $primary-red;
        font-family: "AzoSans-Bold";
        margin-bottom: -5px;

        @media (max-width: 768px) {
          border-bottom: 7px solid $primary-red;
          padding-bottom: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .month-reserv-restaurant-header {
    align-items: center;
    margin-bottom: 25px;
  }
}
</style>