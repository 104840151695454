<template>
  <div class="filter-check_content-fitlers-item">
    <a
      class="filter-check_content-fitlers-header collapsed"
      data-bs-toggle="collapse"
      :href="'#' + indexCollapse"
      role="button"
      aria-expanded="true"
      :aria-controls="indexCollapse"
    >
      <span class="filter-check_content-filter-title">{{ name }}</span>
      <span class="filter-check_arrow"><Arrow /></span>
    </a>
    <div
      class="filter-check_content-fitlers-content collapse"
      :id="indexCollapse"
    >
      <div
        class="filter-check_content-fitlers-check"
        v-for="(item, index) in localOptions"
        :key="index"
      >
        <label class="form-check-label" :for="item.value + '_' + index">
          {{ item.name }}
        </label>
        <input
          class="form-check-input-reverse"
          type="checkbox"
          :checked="item.checked"
          :id="item.value + '_' + index"
          @change="updateChecked(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "./icons/Arrow.vue";
export default {
  name: "Filter check",
  props: {
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    indexCollapse: {
      type: String,
      default: "",
    },
  },
  components: {
    Arrow,
  },
  data() {
    return {
      localOptions: this.options,
    };
  },
  methods: {
    updateChecked(item) {
      for (var i = 0; i < this.localOptions.length; i++) {
        if (
          item.type == this.localOptions[i].type &&
          item.value != this.localOptions[i].value
        ) {
          this.localOptions[i].checked = false;
        }
      }
      item.checked = !item.checked;
      this.$emit("update", this.localOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
.filter-check_content-fitlers-item {
  .filter-check_content-fitlers-header {
    display: flex;
    justify-content: center;
    align-items: center;

    &.collapsed {
      .filter-check_arrow {
        transform: rotate(-90deg);
      }
    }

    span.filter-check_content-filter-title {
      font-family: "AzoSans-Bold";
      font-size: 18px;
      line-height: 45px;
      color: $second-grey-color;
      text-decoration: none;
      flex: 1;
    }
  }
}

.filter-check_content-fitlers-content {
  padding-left: 5%;
}

.filter-check_content-fitlers-check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  > label {
    flex: 1;
  }
}
</style>